<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row no-gutters>
        <v-col cols="4">
          <div>
            <div class="grey--text text--lighten-1" style="font-size: small">
              Кадастровый номер
            </div>
            <span class="text-h6 font-weight-light"
            >
              {{ flat.cadNumber }}
            </span>
          </div>
        </v-col>
        <v-col cols="4">
          <div>
            <div class="grey--text text--lighten-1" style="font-size: small">{{
                flat.apartment ? 'помещение' : 'квартира'
              }}
            </div>
            <span class="text-h6 font-weight-light">
            {{ flat.number }}
          </span>
          </div>
        </v-col>
        <v-col
            cols="4"
            class="text--secondary"
        >
          <v-row
              no-gutters
              style="width: 100%"
          >
            <v-col cols="6">
              <div>
                <div class="grey--text text--lighten-1" style="font-size: small">этаж</div>
                <span class="text-h6 font-weight-light">{{ flat.floor ? flat.floor : ' -- ' }}</span>
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <div class="grey--text text--lighten-1" style="font-size: small">подьезд</div>
                <span class="text-h6 font-weight-light"
                      style="line-height: 1.5rem;"> {{ flat.entrances ? flat.entrances : ' -- ' }} </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row dense class="mt-4">
        <v-col cols="12" sm="6" lg="4">
          <p class="text-h4 text--primary">
            Параметры
          </p>
          <div class="text--primary fix-height">
            Площадь: {{ flat.area }} м2 <br/>
            Тип: {{ $lib.text(flat.data.rightType, params.right_types) }}<br/>
            <!--            Собственность: {{ flat.data.rightType }}<br/>-->
          </div>
          <div class="mt-3">
            <v-btn small text @click="dialog_flat=true">Настройка</v-btn>
          </div>
        </v-col>
        <v-divider vertical/>
        <v-col cols="12" sm="6" lg="4">
          <p class="text-h4 text--primary">
            Информация
          </p>
          <div class="text--primary fix-height" style="opacity: 0.6">
            <v-chip v-for="tagID in flat.tags" :key="tagID"
                    class="mr-1 mt-1" small label color="primary">
              <v-icon left x-small>mdi-label</v-icon>
              {{ $lib.text(tagID, params.tags) }}
            </v-chip>
          </div>
          <div class="mt-3">
            <v-btn small text @click="dialog_log=true">Подробнее</v-btn>
          </div>
        </v-col>
        <v-divider vertical/>
        <v-col cols="12" sm="6" lg="4">
          <p class="text-h4 text--primary">
            Жители
          </p>
          <div class="text--primary fix-height">
            Собственники: {{ flat.data.owners }}<br/>
            Зарегистрированно: {{ flat.data.people }}<br/>
          </div>
          <div class="mt-3">
            <v-btn small text @click="dialog_residents=true">Подробнее</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-expansion-panel-content>

    <flat-dialog-form v-model="dialog_flat" :flat="flat" :params="params"/>

    <flat-dialog-residents v-model="dialog_residents" :flat="flat" :params="params"/>

    <flat-dialog-log v-model="dialog_log" :flat="flat" :params="params"/>

  </v-expansion-panel>

</template>

<script>

export default {
  components: {
    "flat-dialog-log": () => import('./flat-dialog-log.vue'),
    "flat-dialog-residents": () => import('./flat-dialog-residents.vue'),
    "flat-dialog-form": () => import('./flat-dialog-form.vue'),
  },
  props: {
    flat: Object,
    params: Object
  },
  data: () => ({
    dialog_flat: false,
    dialog_residents: false,
    dialog_log: false,
    flat_panel_residents: false,
    reveal: false,
  }),
  methods: {
    closeDialogFlat(flat) {
      flat
      // return flat.apartment ? 'помещение' : 'квартира'
    }
  },
}
</script>

<style scoped>

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.fix-height {
  height: 50px;
}
</style>